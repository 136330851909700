import React from "react";
import FileUpload from "src/components/User/FileUpload";
import { RxCross2 } from "react-icons/rx";
import AttachmentLink from "src/components/Admin/AttachmentLink";

export function TaskFileUpload({
  watch,
  index,
  setValue,
  setDeletedAttachments = null,
}) {
  function removeFile(ind) {
    let attachments = watch(`tasks.${index}.attachments`);
    attachments.splice(ind, 1);
    console.log("🚀 ~ removeFile ~ attachments:", attachments);
    setValue(`tasks.${index}.attachments`, attachments);
  }
  const links = watch(`tasks.${index}.attachments`).filter(
    (f) => typeof f === "string"
  );

  const other = watch(`tasks.${index}.attachments`).filter(
    (f) => typeof f !== "string"
  );

  const handleConfirm = (modal) => {
    setDeletedAttachments((prevItems) => [...prevItems, modal]);
    let attachments = watch(`tasks.${index}.attachments`);
    const ind = attachments.indexOf(modal);
    attachments.splice(ind, 1);
    setValue(`tasks.${index}.attachments`, attachments);
  };

  const handleAdd = (value) => {
    console.log("i", index);
    setValue(`tasks.${index}.attachments`, value);
  };

  return (
    <div className="py-2 flex gap-2 items-center flex-wrap">
      <FileUpload
        attachments={watch(`tasks.${index}.attachments`)}
        setAttachments={(value) => handleAdd(value)}
        index={index}
      />
      {other?.map((f, ind) => {
        return (
          <div>
            {f?.name && (
              <div className="group relative border border-customPurple-400 text-customPurple-400 py-1 px-2 rounded-md max-w-[128px] flex items-center justify-center cursor-pointer ">
                <p className="overflow-hidden text-ellipsis truncate text-xs text-purple-600">
                  {f?.name}
                </p>
                <div
                  className="hidden group-hover:inline-block absolute -top-3 -right-3 m-0.5 p-0.5 bg-customPurple-400 rounded-full cursor-pointer"
                  onClick={() => removeFile(ind)}
                >
                  <RxCross2 className="text-white" />
                </div>
              </div>
            )}
          </div>
        );
      })}
      <div className="-mb-2">
        <AttachmentLink
          attachments={links}
          user={true}
          handleConfirm={handleConfirm}
        />
      </div>
    </div>
  );
}
